var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"mycontainer"}},[_c('MglMap',{ref:"map",staticClass:"default-map-xl",attrs:{"accessToken":_vm.accessToken,"mapStyle":_vm.mapStyle,"zoom":_vm.zoom,"center":_vm.center,"preserveDrawingBuffer":true,"width":"100vw","height":"100vh","minZoom":_vm.minZoom,"maxZoom":_vm.maxZoom,"params":_vm.params,"dragRotate":false,"touchZoomRotate":false,"renderWorldCopies":false}},[_vm._l((_vm.vision.couvertures),function(country,index){return _c('MglMarker',{key:index,attrs:{"coordinates":{
        lat: country.lat,
        lng: country.lng,
      },"closeButton":false,"closeOnClick":false}},[_c('span',{attrs:{"slot":"marker"},slot:"marker"},[_c('i',{class:'flag-icon flag-icon-' + country.countryCode.toLowerCase()})])])}),_c('MglFullscreenControl',{attrs:{"position":"top-left","container":_vm.containerElement}}),_c('MglGeojsonLayer',{attrs:{"type":"fill","sourceId":"country-boundaries","source":{
        id: 'country-boundaries',
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1',
      },"layerId":"country-boundaries-id","layer":{
        'source-layer': 'country_boundaries',
        filter: this.contr,
        type: 'fill',
        paint: {
          'fill-color': '#38ff72',
          'fill-opacity': 0.5,
        },
      }}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
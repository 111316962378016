<template>
  <div id="mycontainer">
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :zoom="zoom"
      :center="center"
      :preserveDrawingBuffer="true"
      ref="map"
      class="default-map-xl"
      width="100vw"
      height="100vh"
      :minZoom="minZoom"
      :maxZoom="maxZoom"
      :params="params"
      :dragRotate="false"
      :touchZoomRotate="false"
      :renderWorldCopies="false"
    >
      <MglMarker
        v-for="(country, index) in vision.couvertures"
        :key="index"
        :coordinates="{
          lat: country.lat,
          lng: country.lng,
        }"
        :closeButton="false"
        :closeOnClick="false"
      >
        <!-- <v-icon
          slot="marker"
          @mouseenter="targetedCountry = index"
          @mouseleave="targetedCountry = null"
        > -->
        <span slot="marker">
          <i
            :class="'flag-icon flag-icon-' + country.countryCode.toLowerCase()"
          ></i>
        </span>
        <!-- <div class="pop">
          <MglPopup :closeButton="false" :showed="targetedCountry === index">
            <div class="image">
              <HalfCircleProgress :avancement="vision.avancement" />
            </div>
          </MglPopup>
        </div> -->
      </MglMarker>
      <MglFullscreenControl position="top-left" :container="containerElement" />
      <MglGeojsonLayer
        type="fill"
        sourceId="country-boundaries"
        :source="{
          id: 'country-boundaries',
          type: 'vector',
          url: 'mapbox://mapbox.country-boundaries-v1',
        }"
        layerId="country-boundaries-id"
        :layer="{
          'source-layer': 'country_boundaries',
          filter: this.contr,
          type: 'fill',
          paint: {
            'fill-color': '#38ff72',
            'fill-opacity': 0.5,
          },
        }"
      />
    </MglMap>
  </div>
</template>
<script>
import {
  MglMap,
  MglFullscreenControl,
  // MglPopup,
  MglMarker,
  MglGeojsonLayer,
} from "vue-mapbox";
import worldCountries from "@/assets/countries/worldCountries.json";
// import HalfCircleProgress from "@/components/halfCircleProgress/HalfCircleProgress.vue";

export default {
  components: {
    MglMap,
    MglFullscreenControl,
    // MglPopup,
    MglMarker,
    // HalfCircleProgress,
    MglGeojsonLayer,
  },

  props: {
    countries: {
      type: Array,
      default: () => [],
    },
    vision: Object,
    contr: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: ["in", "iso_3166_1"],
    },
  },

  data() {
    return {
      accessToken:
        "pk.eyJ1Ijoib3Vzc2FtYW1vdXRpYSIsImEiOiJja3lsZXZmODUzNW5tMndvODBkbGRuZ3EyIn0.3crdq2xwtwl1fF-3AJIT_w",
      mapStyle: "mapbox://styles/oussamamoutia/ckywtcztm000u14ltadeq6xic/draft",
      zoom: 2,
      center: [2.2137, 46.2276],
      containerElement: null,
      worldCountries: worldCountries,
      preserveDrawingBuffer: true,
      // showPopup : false,
      targetedCountry: null,
      x: null,
      pup: {
        closeButton: false,
        closeOnClick: false,
      },
      minZoom: 1,
      maxZoom: 14,
      params: {
        access_token:
          "pk.eyJ1Ijoib3Vzc2FtYW1vdXRpYSIsImEiOiJja3lsZXZmODUzNW5tMndvODBkbGRuZ3EyIn0.3crdq2xwtwl1fF-3AJIT_w",

        country: "fra",
        language: "fr",
      },
    };
  },
  created() {
      if (this.$route.path.includes("print")) {
      this.zoom = 1;
    }
  },
  mounted() {
    this.containerElement = document.getElementById("mycontainer");
    var content = document.createElement("div");
    content.parentNode.parentNode.className += " mapboxgl-popup--blue";
    if (this.$route.path.includes("print")) {
      this.zoom = 1;
    }
    if (this.vision.couvertures.length === 1) {
      this.center = [
        this.vision.couvertures[0].lat,
        this.vision.couvertures[0].lng,
      ];
    }
  },

  // created(){
  // }
  methods: {},
  watch: {
    vision() {
      if (this.vision.couvertures.length === 1) {
        this.center = [
          this.vision.couvertures[0].lat,
          this.vision.couvertures[0].lng,
        ];
      }
    },
  },
};

let mbLogo = window.document.querySelectorAll(".mapboxgl-ctrl");

if (mbLogo.length > 0) {
  mbLogo.forEach((it) => {
    it.remove();
  });
}
</script>

<style lang="scss">
.default-map-xl {
  width: 100% !important;
  height: 70vh !important;
  /* background: green ; */
}

.test {
  border-radius: 2rem;
  position: relative;
}
</style>
<style>
.mb-opacity-0 {
  opacity: 0;
}

.mapboxgl-ctrl {
  display: none !important;
}
@page {
  size: auto; /* auto is the initial value */
  /* div #map {
    width: 100%;
    height: 100%;
    margin: 0mm;
    pabbing: 0mm;
  } */
}
</style>

<template>
  <section class="tables">
    <div class="row">
      <div class="col-12 p-0">
        <p class="h2 text-secondary">
          <i style="font-size: 22px; margin-left: 2%" :class="$route.meta.iconClass"></i>
          <span style="font-size: 18px; margin-left: 0.5%" class="font-weight-light"
            >Objectif stratégique :
          </span>
          <span style="font-size: 22px">{{ objectif.libelle }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <!-- <h4 class="card-title mt-4 mb-1" style="margin-left: 2%">
          Liste feuilles de route :
        </h4> -->
        <hr />
      </div>
    </div>
    <div class="d-flex col-12 p-0 mt-4" style="gap: 5px">
      <div class="col-md-6 shdow">
        <div>
          <span class="h4 text-secondary">Informations générales</span>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <p style="color: black">
          Objectif stratégique :
          <span class="font-weight-bold" style="color: black">{{
            objectif.libelle
          }}</span>
        </p>

        <p style="color: black">
          Organisation responsable :
          <span class="font-weight-bold" style="color: black">{{
            objectif.organisationResp.libelle
          }}</span>
        </p>
        <p style="color: black">
          Responsable :
          <span class="font-weight-bold" style="color: black">{{
            objectif.responsable.firstname + " " + objectif.responsable.lastname
          }}</span>
        </p>
      </div>
      <div class="col-md-6 shdow">
        <div>
          <span class="h4 text-secondary">Avancement</span>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <p style="color: black" class="d-flex col-12 p-0">
          Avancement :
          <span class="font-weight-bold col-6 p-0 my-auto" style="color: black">
            <b-progress class="col-12 p-0 ml-1" max="100" show-progress height="15px">
              <b-progress-bar
                :value="objectif.avancement"
                :label="`${objectif.avancement}%`"
                :style="{ backgroundColor: variant(objectif.avancement) }"
              ></b-progress-bar> </b-progress
          ></span>
        </p>
        <p style="color: black">
          Alignement :
          <span class="font-weight-bold" style="color: black">{{
            objectif.feuilleRoutes.length
          }}</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    objectif: Object,
    visionName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
  },
};
</script>

<style>
@media (min-width: 600px) {
  .align-btn-table {
    position: relative;
    left: -112px;
  }
}
</style>

import { render, staticRenderFns } from "./FdrList.vue?vue&type=template&id=67e6909e"
import script from "./FdrList.vue?vue&type=script&lang=js"
export * from "./FdrList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
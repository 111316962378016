<template>
  <section class="tables">
    <b-table
      responsive
      :items="tableItems"
      :fields="fields"
      :current-page="currentPage"
      :sortable="!$route.path.includes('print')"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :per-page="!$route.path.includes('print') ? perPage : tableItems.length"
      show-empty
      bordered
      striped
    >
      <template v-slot:empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(libelle)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
        >
          <b-link
            v-if="!$route.path.includes('print')"
            v-html="data.value"
            style="cursor: pointer"
            :to="{
              name: 'show-objectif',
              params: {
                id: data.item.id,
                slug: data.item.slug || 'abcde',
              },
            }"
          ></b-link>
          <span v-else> {{ data.value }} </span>
        </span>

        <input
          v-else
          type="text"
          class="form-control"
          v-model="data.item.libelle"
          placeholder="Libellé"
        />
      </template>
      <template v-slot:cell(desription)="data">
        <div
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          class="text-truncate"
          style="max-width: 100px"
        >
          <span v-html="data.value || '-'"></span>
        </div>
        <input
          v-else
          type="text"
          class="form-control"
          v-model="data.item.desription"
          placeholder="Description"
        />
      </template>

      <template v-slot:cell(organisationResp)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          v-html="data.value.libelle ? data.value.libelle.toUpperCase() : '-'"
        ></span>
        <b-form-select
          v-else
          :options="customOrganisations"
          v-model="data.item.organisationResp"
          :value="data.value"
        />
      </template>
      <template v-slot:cell(responsable)="data">
        <div
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
        >
          <TableResponsable
            v-if="!$route.path.includes('print')"
            :responsable="data.value"
          />
          <span v-else>
            {{ data.value.firstname + " " + data.value.lastname }}
          </span>
        </div>

        <b-form-select
          v-else
          :options="customResponsables"
          v-model="data.item.responsable"
          :value="data.value"
          placeholder="Responsable"
        />
      </template>
      <template v-slot:cell(kpis)="data">
        <b-badge
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          pill
          @click="$emit('showFdrListModal')"
          style="
            cursor: pointer;
            background-color: #adb5bd;
            border-color: #adb5bd;
          "
          >{{ data.value.length || 0 }}</b-badge
        >
        <input
          v-else
          v-model="data.item.kpis"
          type="text"
          class="form-control"
          placeholder="Kpis"
        />
      </template>
      <template v-slot:cell(avancement)="data">
        <span style="color: black" class="col-6 p-0 ml-1 my-auto">
          <b-progress
            class=""
            max="100"
            show-progress
            height="15px"
            style="background-color: #cacccd !important"
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          >
            <b-progress-bar
              :value="data.value"
              :label="`${data.value}%`"
              :style="{ backgroundColor: variant(data.value) }"
            ></b-progress-bar>
          </b-progress>
          <section v-else class="simple-slider">
            <VueSlideBar class="pt-3" v-model="data.item.avancement" />
          </section>
        </span>
      </template>

      <template v-slot:cell(feuilleRoutes)="data">
        <div class="text-center">
          <b-badge
            v-if="data.value.length"
            pill
            @click="showFdrListModalFunction(data.item)"
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
          >
            {{ data.value.length }}</b-badge
          >
          <b-badge
            v-else
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            :style="{ display: disablealignement(disabeleAlignment) }"
            >{{ data.value.length }}</b-badge
          >
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <table-actions
          :actions="isAdmin || isSuper || isManager ? data.value : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @cancelItem="cancelItem(data.item, data.index)"
          @confirmItem="confirmItem(data.index, data.item)"
          @deleteItem="$emit('deleteItem', data.index, data.item)"
          @editItem="$emit('editItem', data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-if="!$route.path.includes('print')"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>

    <div v-if="showGoalModal" to="modals-xyz-500">
      <Modal @close="showGoalModal = false">
        <GoalModal :objectif="selectedObject" :visionName="visionName" />
      </Modal>
    </div>
    <div v-if="showFdrListModal" to="modals-xyz-500">
      <Modal @close="showFdrListModal = !showFdrListModal">
        <fdr-list :visionName="visionName" :objectif="selectedObject" />
      </Modal>
    </div>
  </section>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import VueSlideBar from "vue-slide-bar";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import Modal from "@/components/custom/Modal.vue";
import GoalModal from "./GoalModal.vue";
import FdrList from "./FdrList.vue";

export default {
  components: {
    TableActions,
    VueSlideBar,
    TableResponsable,
    Modal,
    GoalModal,
    FdrList,
  },
  props: {
    objectifs: Array,
    fields: Array,
    visionName: {
      type: String,
      default: "",
    },
    responsables: Array,
    organisations: Array,
    disabeleAlignment: Boolean,
  },
  data() {
    return {
      perPage: 10,
      tableItems: [],
      showGoalModal: false,
      showFdrListModal: false,
      i: 0,
      objectifItems: [],
      sortBy: "name",
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedObject: null,
      objectif: {
        libelle: null,
        description: null,
        organisationResp: null,
        responsable: null,
        kpis: null,
        avancement: null,
        alignement: null,
        actions: ["delete"],
      },
    };
  },
  mounted() {},
  watch: {
    objectifs() {
      this.tableItems = this.objectifs;
    },
  },
  created() {
    this.$store.dispatch("objectif/fetchAllObjectifs");
  },
  computed: {
    ...mapGetters("objectif", ["OBJECTIFS"]),
    ...mapGetters(["isAdmin", "isSuper", "isManager"]),

    rows() {
      return this.tableItems.length;
    },
    customResponsables: function () {
      return this.responsables.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: ` ${responsable.firstname}  ${responsable.lastname}`,
      }));
    },
    customOrganisations: function () {
      return this.organisations.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
  },

  methods: {
    showGoalModalFunction(item) {
      this.selectedObject = item;
      this.showGoalModal = true;
    },
    showFdrListModalFunction(item) {
      this.selectedObject = item;
      this.showFdrListModal = true;
    },
    test() {
      let t = "";
      this.tableItems.forEach((element) => {
        t = element.organisationResp.libelle.toUpperCase();
      });
      return t;
    },
    clone(data) {
      let d = new Array();
      d.id = data.id;
      d.description = data.description;
      d.libelle = data.libelle;
      d.responsable.id = data.responsable.id;
      d.avancement = data.avancement;
      d.kpi = data.kpis;
      d.organisationResp.id = data.organisationResp.id;
      return d;
    },
    update(index, data) {
      this.objectif = data;
      this.i = index;
    },
    confirmItem: function (index, data) {
      if (data.id == null) {
        this.$store
          .dispatch("objectif/createObjectif", {
            ...data,
            vision: `/api/strategy/visions/${this.$route.params.id}`,
          })
          .then(() => {
            this.$store.dispatch("vision/fetchVision", this.$route.params.id);
          });

        this.$emit("confirmItem", index, data);
        this.$store.dispatch("vision/fetchVision", this.$route.params.id);
      } else {
        this.$store
          .dispatch("objectif/updateObjectif", {
            ...data,
            vision: `/api/strategy/visions/${this.$route.params.id}`,
          })
          .then(() => {
            this.$store.dispatch("vision/fetchVision", this.$route.params.id);
          });
        //  this.tableItems= data;
        //  this.$emit("confirmItem", index,data);
        // this.$store.dispatch("objectif/fetchObjectif",this.$route.params.id)
      }
    },

    cancelItem: function (item) {
      if (
        item.libelle == "" &&
        item.avancement == 0 &&
        item.desription == "" &&
        item.organisationResp == "" &&
        item.responsable == ""
      ) {
        this.$store.dispatch("vision/fetchVision", this.$route.params.id);
      } else {
        (item.libelle = ""),
          (item.avancement = 0),
          (item.desription = ""),
          (item.organisationResp = ""),
          (item.responsable = "");
      }
      // this.$emit("confirmItem", index, item);
    },

    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
    disablealignement(data) {
      if (data) {
        return "none";
      }
    },
  },
};
</script>

<style>
@media (min-width: 600px) {
  .align-btn-table {
    position: relative;
    left: -112px;
  }
}
</style>
